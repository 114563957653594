import {defineStore} from 'pinia';


export const useContainerStore = defineStore('container_master_store', {
    state: () => ({
        data:{
            id:null,
            component_id:null,
            is_active:false,
            user_limit:0,
            objects_count:0,
            items_count:0,
        },
    }),

    getters:{
        getUserLimit: (state) => state.data.user_limit,
        getObjectsLimit: (state) => state.data.objects_count,
        getObjectsCount: (state) => state.data.items_count,
    },

    actions:{
        setData(val){
            this.data = val;
        },
    },

    persist: false,
})